import { FC, useState } from "react";
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, 
    DialogTitle, Divider, IconButton, TextField } from "@mui/material";
import { Box, Typography } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface IConfirm {
    open: boolean;
    message: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const Confirm: FC<IConfirm> = (props: IConfirm) => {

    return (
        <>
            <Dialog 
                open={props.open} 
                onClose={props.onCancel}
                fullWidth
                maxWidth="xs"
                sx={{
                    '& .MuiPaper-root' : {
                        position: 'absolute',
                        top: 100
                    }
                }}
            >
                <DialogTitle>
                    <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                        <ErrorOutlineIcon />
                        <Typography>Alert</Typography>
                    </Box>
                </DialogTitle>

                <Divider />

                <DialogContent sx={{p: 3}}>
                    <Typography>{ props.message }</Typography>
                </DialogContent>

                <Divider />
                
                <DialogActions sx={{p: 2}}>
                    <Button variant="outlined" size="small" onClick={props.onCancel}>Cancel</Button>
                    <Button variant="outlined" size="small" onClick={props.onConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Confirm;