import { Models } from "@rematch/core";
import { CodeFiles } from "./handleFile";
import { ToolbarEvents } from "./handleEvent";

export interface RootModel extends Models<RootModel> {
    CodeFiles: typeof CodeFiles;
    ToolbarEvents: typeof ToolbarEvents;
}

export const models: RootModel = {
    CodeFiles,
    ToolbarEvents
};