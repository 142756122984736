// src/pages/CodeEditor.tsx

import { FC } from "react";
import "../assets/editor.scss";
import { Box, Card, CardHeader, Container, CssBaseline, Divider } from "@mui/material";
import FileList from "../components/FileList";
import FileEditor from "../components/FileEditor";
import EditorToolBar from "../components/EditorToolBar";
import { useSelector } from "react-redux";
import { RootState } from "../store";


const CodeEditor: FC = () => {

    const selectedFile = useSelector((state: RootState) => state.CodeFiles.selectedFile);

    return (
        <>
            <Container maxWidth="lg" sx={{paddingTop: 4}}>
                <Card>
                    <CardHeader 
                        title={selectedFile ? selectedFile.name : 'Code Editor'}
                    />

                    <Divider />

                    <Box sx={{display: "flex"}}>
                        <FileList />
                        <Box sx={{width: '100%'}}>
                            <EditorToolBar />
                            <FileEditor />
                        </Box>
                    </Box>
                    
                </Card>
            </Container>
        </>
    )
}

export default CodeEditor;