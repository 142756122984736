import { ChangeEvent, FC, useState } from 'react';
import { RootState, Dispatch } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, FormControlLabel, IconButton, styled, Switch } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined';
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;

const CEToolBar = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    ...(open && {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const EditorToolBar: FC = () => {

    const dispatch = useDispatch<Dispatch>();
    const codeChanged = useSelector((state: RootState) => state.ToolbarEvents.changed);
    const drawOpened = useSelector((state: RootState) => state.ToolbarEvents.drawOpend);
    const darkModeEvent = useSelector((state: RootState) => state.ToolbarEvents.darkMode);
    const [darkMode, setDarkMode] = useState<boolean>(false);

    /**
     * Dispatch save only if save state is false
     */
    const handleSave = () => {
        codeChanged && dispatch.ToolbarEvents.setSave(true);
    }

    /**
     * Save As
     */
    const handleSaveAs = () => {
        dispatch.ToolbarEvents.setSaveAs(true);
    }

    const handleSaveAll = () => {
        dispatch.ToolbarEvents.setSaveAll(true);
    }

    const handleCopy = () => {
        dispatch.ToolbarEvents.setCopy(true);
    }

    const handleDrawerOpen = () => {
        dispatch.ToolbarEvents.setDrawOpened(true);
    };

    return (
        <>
            <CEToolBar open={drawOpened}>
                <Box sx={{ display: "flex", padding: "5px 12px", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", gap: 2 }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ p: 0, ml: 0, mr: 2, ...(drawOpened && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Button variant="text"
                            startIcon={<SaveOutlinedIcon />}
                            onClick={handleSave}
                            color="inherit">Save
                        </Button>

                        <Button variant="text"
                            startIcon={<SaveAsOutlinedIcon />}
                            onClick={handleSaveAs}
                            color="inherit">Save As
                        </Button>

                        <Button variant="text"
                            startIcon={<CollectionsBookmarkOutlinedIcon />}
                            onClick={handleSaveAll}
                            color="inherit">Save All
                        </Button>

                        <Button variant="text"
                            startIcon={<ContentPasteGoOutlinedIcon />}
                            onClick={handleCopy}
                            color="inherit">Copy Clipboard
                        </Button>
                    </Box>
                    <Box className="center"></Box>
                    <Box className="after">
                        <FormControlLabel control={
                            <Switch checked={darkModeEvent} 
                                onChange={(e) => {dispatch.ToolbarEvents.setDarkMode(e.target.checked);}} 
                            />
                        } label="Dark Mode" />
                    </Box>
                </Box>
            </CEToolBar>
        </>
    )
}

export default EditorToolBar;