import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { FileState, TFile, TFiles } from "../../types";

export const initState: FileState = {
    files: [],
    openedFiles: [],
    selectedFile: undefined
}

export const CodeFiles = createModel<RootModel>()({
    state: initState,
    reducers: {
        init: () => initState,
        setFiles: (state: FileState, payload: TFile[]) => {
            return {
                ...state,
                files: payload
            }
        },
        setSelectedFile: (state: FileState, payload: TFile) => {
            return {
                ...state,
                selectedFile: payload
            }
        }
    },
    effects: (dispatch) => ({
        onInit(_payload, _state) {
            dispatch.CodeFiles.init();
        },
        async setFilesAsync(payload: TFile[], state) {
            console.log("This is current root state", state);
            await new Promise((resolve) => setTimeout(resolve, 1000));
            dispatch.CodeFiles.setFiles(payload);
        },
    }),
});
