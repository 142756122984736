import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { store } from './store/index';
import { initService } from './services';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const start = () => {
    initService();
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>
    );
}

start();
reportWebVitals();
