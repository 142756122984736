// src/components/FileEditor.tsx

import { FC, useCallback, useEffect, useRef, useState } from "react";
import CodeEditor, { SelectionText } from "@uiw/react-textarea-code-editor";
import { RootState, Dispatch } from '../store';
import { useDispatch, useSelector } from "react-redux";
import { codeFileService } from "../services";
import { TFile } from "../types";
import { styled, useTheme } from '@mui/material/styles';
import { Box, IconButton, Tab, Tabs } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SaveAsModal from './popups/SaveAs';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    minHeight: '600px',
    height: 'calc(100% - 50px)',
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

type TCode = {
    name: string;
    code: string;
}

const FileEditor: FC = () => {

    const dispatch = useDispatch<Dispatch>();
    const textRef = useRef<HTMLTextAreaElement | any>();
    const selectedFile = useSelector((state: RootState) => state.CodeFiles.selectedFile);
    const saveEvent = useSelector((state: RootState) => state.ToolbarEvents.save);
    const saveAsEvent = useSelector((state: RootState) => state.ToolbarEvents.saveAs);
    const saveAllEvent = useSelector((state: RootState) => state.ToolbarEvents.saveAll);
    const copyEvent = useSelector((state: RootState) => state.ToolbarEvents.copy);
    const drawOpened = useSelector((state: RootState) => state.ToolbarEvents.drawOpend);
    const darkModeEvent = useSelector((state: RootState) => state.ToolbarEvents.darkMode);

    const [code, setCode] = useState<string>('');
    const [language, setLanguage] = useState<string>('js');
    const [codes, setCodes] = useState<TCode[]>([]);
    const [tabs, setTabs] = useState<string[]>([]);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [openSaveAsDialog, setOpenSaveAsDialog] = useState<boolean>(false);

    /**
     * Get File Content From Server
     * @param file 
     */
    const getFileContent = async (file: TFile) => {

        if (file.name !== '') {
            const fileContentResponse = await codeFileService.getCodeFileContent(file.name);
            fileContentResponse && setCode(fileContentResponse);

            fileContentResponse && ![...codes].some(c => c.name === file.name)
                && setCodes([...codes, {
                    name: file.name,
                    code: fileContentResponse
                }]);

            fileContentResponse && ![...tabs].some(tab => tab === file.name)
                && setTabs([...tabs, file.name]);

            fileContentResponse && setCode(fileContentResponse);

            setActiveTab(tabs.length);
        }
    }

    /**
     * Load Selected File from File list
     */
    useEffect(() => {

        if (selectedFile !== undefined) {
            selectedFile.ext === '.js' && setLanguage('js');
            selectedFile.ext === '.py' && setLanguage('python');
            selectedFile.ext !== '.js' && selectedFile.ext !== '.py' && setLanguage('text');

            // Check Already opened or not
            if (!tabs.includes(selectedFile.name)) {
                getFileContent(selectedFile);
            } else {
                const tabIndex = tabs.indexOf(selectedFile.name);
                setActiveTab(tabIndex);
            }            
        }

    }, [selectedFile]);

    /**
     * Handle Code Change
     * @param evn 
     */
    const handleCodeChange = (evn: any) => {
        setCode(evn.target.value);
        dispatch.ToolbarEvents.setCodeChanged(true);
    }

    /**
     * Save Code
     */
    const handleSaveCode = async () => {

        const saveCodeResponse = await codeFileService.saveCodeFileContent({
            fileName: tabs[activeTab],
            data: code
        });

        if (saveCodeResponse.success) {
            dispatch.ToolbarEvents.setSave(false);
            dispatch.ToolbarEvents.setCodeChanged(false);
            alert('File saved');
        }
    }

    /**
     * SaveEvent recieved From Toolbar
     */
    useEffect(() => {

        if (saveEvent) {
            handleSaveCode();
        }
    }, [saveEvent]);

    /**
     * Handle Save As
     * @param name Filename
     */
    const handleSaveAsConfirm = async (name: string) => {

        if (code !== '') {
            const saveCodeResponse = await codeFileService.saveCodeFileContent({
                fileName: name,
                data: code
            });
    
            if (saveCodeResponse.success) {
                dispatch.ToolbarEvents.setSave(false);
                dispatch.ToolbarEvents.setCodeChanged(false);
                alert('File saved');
            }
        }
        setOpenSaveAsDialog(false);
        dispatch.ToolbarEvents.setSaveAs(false);
    }

    /**
     * SaveAs Event from Toolbar
     */
    useEffect(() => {
        saveAsEvent && setOpenSaveAsDialog(saveAsEvent);
    }, [saveAsEvent]);

    /**
     * Handle Save All
     */
    const handleSaveAll = async () => {
        if (tabs.length > 0) {
            tabs.map(async (tab: string) => {
                const codeObj = codes.filter((code: TCode) => code.name === tab)[0];
                await codeFileService.saveCodeFileContent({
                    fileName: tab,
                    data: codeObj.code
                });
            });

            alert('Successfully Saved All');
        }
    }

    /**
     * SaveAll Event from Toolbar
     */
    useEffect(() => {
        saveAllEvent && handleSaveAll();
    }, [saveAllEvent])

    /**
     * Copy Clipboard Event from Toolbar
     */
    useEffect(() => {
        if (code !== '' && copyEvent) {
            navigator.clipboard.writeText(code);
            dispatch.ToolbarEvents.setCopy(false);
            alert('Copied current code');
        }
    }, [copyEvent]);

    /**
     * Active Tab handle
     */
    useEffect(() => {
        const fileName = tabs[activeTab];
        const codeObj = codes.filter((code: TCode) => code.name === fileName)[0];
        codeObj && setCode(codeObj.code);
    }, [activeTab])

    /**
     * Handle Change Active File Tab
     * @param tab 
     */
    const handleChangeTab = (tabToActive: string) => {
        const tabIndex = tabs.findIndex(tab => tab === tabToActive);
        const codeObj = codes.filter((code: TCode) => code.name === tabToActive)[0];
        setActiveTab(tabIndex);
        setCode(codeObj.code);
        tabToActive.includes('.js') && setLanguage('js');
        tabToActive.includes('.py') && setLanguage('python');
        !tabToActive.includes('.js') && !tabToActive.includes('.py') && setLanguage('text');
    }

    /**
     * Handle Close File Tab
     * @param event 
     * @param tabToDelete 
     */
    const handleCloseFile = (event: any, tabToDelete: string) => {
        event.stopPropagation();
        const tabToDeleteIndex = tabs.findIndex(
            tab => tab === tabToDelete
        );
        let updatedTabs = [...tabs];
        updatedTabs.splice(tabToDeleteIndex, 1);
        setTabs(updatedTabs);
        setActiveTab(0);
    };

    return (
        <>
            <Main open={drawOpened}>
                {tabs.length > 0 &&
                    <Box sx={{backgroundColor: '#323130'}} 
                        className="tabs-wrap" >
                        <Tabs value={activeTab}
                            className="ce-tabs"
                            TabIndicatorProps={{
                                style: { display: 'none' }
                            }}
                        >
                            {tabs.map((tab: string, idx: number) => (
                                <Tab key={idx}
                                    label={
                                        <span>
                                            {tab}
                                            <IconButton
                                                component="div"
                                                onClick={event => handleCloseFile(event, tab)}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </span>
                                    }
                                    className={idx === activeTab ? 'active' : ''}
                                    onClick={() => handleChangeTab(tab)}
                                    // sx={ darkModeEvent 
                                    //     ? { 
                                    //         color: '#ddd !important',
                                    //         '&.active' : { backgroundColor: '#161b22' } ,
                                    //         '& .MuiIconButton-root' : { color: '#ddd !important' }
                                    //     } 
                                    //     : { 
                                    //         color: '#444 !important',
                                    //         '&.active' : { backgroundColor: '#f6f8fa' },
                                    //         '& .MuiIconButton-root' : { color: '#444 !important' }
                                    //     }
                                    // }
                                />
                            ))}
                        </Tabs>
                    </Box>
                }

                {tabs.length > 0 ? 
                    <div className="ce-tab-panel" data-color-mode="dark">
                        <CodeEditor
                            value={code}
                            ref={textRef}
                            language={language}
                            placeholder="Please enter JS code."
                            onChange={(evn) => handleCodeChange(evn)}
                            padding={15}
                            style={{
                                fontSize: 12,
                                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                minHeight: 500,
                                height: 600,
                                overflowY: 'auto'
                            }}
                        />
                    </div> : 
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#ccc',
                        fontWeight: '600',
                        fontSize: '20px'
                    }}>
                        Please Select File
                    </Box>}

            </Main>

            <SaveAsModal 
                open = {openSaveAsDialog}
                fileName = {tabs[activeTab]}
                onConfirm = {handleSaveAsConfirm}
                onCancel = {() => { 
                    setOpenSaveAsDialog(false);
                    dispatch.ToolbarEvents.setSaveAs(false);
                }}
            />
        </>
    )
}

export default FileEditor;