import ApiClient, { ApiConfiguration } from "./ApiClient";
import CodeFilesService, { CodeFilesApiClient } from "./CodeFiles";

export let codeFileService: CodeFilesService;

export const initService = () => {
    const codeFileApiConfig = new ApiConfiguration();

    codeFileApiConfig.baseUrl = process.env.NODE_ENV == 'production' ? '' : "http://localhost:5000/";
    const codeFileApiClient = new CodeFilesApiClient(
        new ApiClient(codeFileApiConfig)
    );
    codeFileService = new CodeFilesService(codeFileApiClient);
};