import { FC, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, 
    DialogTitle, Divider, TextField } from "@mui/material";

interface ISaveAs {
    open: boolean;
    fileName: string;
    onConfirm: (name: string) => void;
    onCancel: () => void;
}

const SaveAs: FC<ISaveAs> = (props: ISaveAs) => {

    const [newFileName, setNewFileName] = useState<string>(props.fileName);

    return (
        <>
            <Dialog 
                open={props.open} 
                onClose={props.onCancel}
                fullWidth
                maxWidth="xs"
                sx={{
                    '& .MuiPaper-root' : {
                        position: 'absolute',
                        top: 100
                    }
                }}
            >
                <DialogTitle>Save As</DialogTitle>
                <Divider />
                <DialogContent sx={{p: 3}}>
                    <TextField
                        label="File Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={newFileName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setNewFileName(event.target.value);
                        }} />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="contained" onClick={props.onCancel}>Cancel</Button>
                    <Button variant="contained" onClick={() => { props.onConfirm(newFileName) }}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SaveAs;