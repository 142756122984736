import { FC } from "react";
import CodeEditor from "./pages/CodeEditor";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector } from "react-redux";
import { RootState } from "./store";

const App: FC = () => {

	const darkModeEvent = useSelector((state: RootState) => state.ToolbarEvents.darkMode);

	const theme = createTheme({
		palette: {
			mode: darkModeEvent ? 'dark' : 'light',
		},
	});

	return (
		<>
			<ThemeProvider theme={theme}>
				<CodeEditor />
			</ThemeProvider>
		</>
	)
}

export default App;