// src/components/FileList.tsx
import { FC, useEffect, useState } from "react";
import jsFileSvg from "../assets/file-js.svg";
import pythonFileSvg from "../assets/file-python.svg";
import unknownFileSvg from "../assets/file-unknown.svg";
import { codeFileService } from "../services";
import { TFile } from "../types";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch, RootState } from "../store";
import { Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, Tooltip } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Confirm from "./popups/Confirm";

enum ext {
    javascript = '.js',
    python = '.py'
}

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const FileList: FC = () => {

    const dispatch = useDispatch<Dispatch>();
    const [files, setFiles] = useState<TFile[]>();
    const [activeFile, setActiveFile] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<TFile>();
    const [deleteFile, setDeleteFile] = useState<TFile>();
    const [message, setMessage] = useState<string>('');
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const drawOpened = useSelector((state: RootState) => state.ToolbarEvents.drawOpend);
    const darkModeEvent = useSelector((state: RootState) => state.ToolbarEvents.darkMode);

    useEffect(() => {

        getCodeFiles();
    }, []);

    /**
     * Get Files name
     */
    const getCodeFiles = async () => {

        const getFilesResponse = await codeFileService.getCodeFiles();

        // Store files to redux
        getFilesResponse && dispatch.CodeFiles.setFiles(getFilesResponse);

        // Set Files
        getFilesResponse && setFiles(getFilesResponse);
    }

    /**
     * Set Active Selected File
     * @param fileName TFile
     */
    const handleActiveFile = (fileName: string) => {
        setActiveFile(fileName);
    }

    /**
     * Dispatch selected File to File Editor
     * @param file TFile
     */
    const handleSelectFile = async (file: TFile) => {
        setSelectedFile(file);
        dispatch.CodeFiles.setSelectedFile(file);
    }

    /**
     * Handle Drawer Close to hide file list
     */
    const handleDrawerClose = () => {
        dispatch.ToolbarEvents.setDrawOpened(false);
    };

    /**
     * Delete a File
     * @param e Event
     * @param file TFile
     */
    const handleDeleteFile = (e: any, file: TFile) => {
        e.stopPropagation();
        setMessage('Are you sure you want to delete this file?');
        setDeleteFile(file);
        setOpenConfirmModal(true);
    }

    /**
     * Delete selected File
     */
    const handleDeleteFileConfirm = async () => {

        if (deleteFile !== undefined) {
            const deleteResponse = await codeFileService.deleteFile(deleteFile.name);

            if (deleteResponse) {
                await getCodeFiles();
            }
        }

        setOpenConfirmModal(false);
    }

    const handleCopyFile = async (e: any, file: TFile) => {
        e.stopPropagation();
        const copyResponse = await codeFileService.copyFile(file.name);

        if (copyResponse) {
            await getCodeFiles();
        }
    }

    return (
        <>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        position: 'relative',
                        '& >div': {
                            minHeight: '47px'
                        }
                    }
                }}
                variant="persistent"
                anchor="left"
                open={drawOpened}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </DrawerHeader>
                
                <List sx={{padding: '0 12px'}}>
                    {files?.map((file: TFile, idx: number) => (
                        <ListItem key={file.name} disablePadding>
                            <ListItemButton 
                                className={file.name === activeFile || file.name == selectedFile?.name ? "file-item active" : "file-item"}
                                onClick={() => handleActiveFile(file.name)}
                                onDoubleClick={() => handleSelectFile(file)}
                                sx={{ 
                                    '&.active': darkModeEvent ? {backgroundColor: '#333'} : {backgroundColor: '#eee'},
                                    padding: '6px 0px 6px 10px'
                                }}
                            >
                                <ListItemIcon sx={{minWidth: '30px'}}>
                                    { file.ext === ext.javascript && <img className="file-icon" src={ jsFileSvg } /> }
                                    { file.ext === ext.python && <img className="file-icon" src={ pythonFileSvg } /> }
                                    { file.ext !== ext.javascript && file.ext !== ext.python && <img className="file-icon" src={ unknownFileSvg } /> }
                                </ListItemIcon>
                                <Tooltip title={file.name} placement="bottom">
                                    <ListItemText primary={file.name} 
                                        sx={{
                                            '& .MuiTypography-root': {
                                                fontSize: '.8rem',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }
                                        }} /> 
                                </Tooltip>
                                
                                <IconButton onClick={(e) => handleCopyFile(e, file)}>
                                    <ContentCopyIcon sx={{fontSize: '1rem'}} />
                                </IconButton>
                                <IconButton onClick={(e) => handleDeleteFile(e, file)}>
                                    <CloseIcon sx={{fontSize: '1rem'}} />
                                </IconButton>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                
            </Drawer>

            <Confirm 
                open={openConfirmModal} 
                message={message} 
                onCancel={() => setOpenConfirmModal(false)} 
                onConfirm={handleDeleteFileConfirm} 
            />
        </>
    )
}

export default FileList;