import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { ToolbarEvent } from "../../types";

export const initState: ToolbarEvent = {
    save: false,
    saveAs: false,
    saveAll: false,
    copy: false,
    changed: false,
    drawOpend: true,
    darkMode: false,
}

export const ToolbarEvents = createModel<RootModel>()({
    state: initState,
    reducers: {
        init: () => initState,
        setSave: (state: ToolbarEvent, payload: boolean) => {
            return {
                ...state,
                save: payload
            }
        },
        setSaveAs: (state: ToolbarEvent, payload: boolean) => {
            return {
                ...state,
                saveAs: payload
            }
        },
        setSaveAll: (state: ToolbarEvent, payload: boolean) => {
            return {
                ...state,
                saveAll: payload
            }
        },
        setCopy: (state: ToolbarEvent, payload: boolean) => {
            return {
                ...state,
                copy: payload
            }
        },
        setCodeChanged: (state: ToolbarEvent, payload: boolean) => {
            return {
                ...state,
                changed: payload
            }
        },
        setDrawOpened: (state: ToolbarEvent, payload: boolean) => {
            return {
                ...state,
                drawOpend: payload
            }
        },
        setDarkMode: (state: ToolbarEvent, payload: boolean) => {
            return {
                ...state,
                darkMode: payload
            }
        },
    },
    effects: (dispatch) => ({
        onInit(_payload, _state) {
            dispatch.ToolbarEvents.init();
        }
    }),
});
